<template>
	<div
		class="input-wrapper"
		:class="[
			size,
			{ disabled },
			{ dark },
			{
				error: $slots['error'],
				warning: $slots['warning'],
				info: $slots['info'],
				success: $slots['success'],
				focus: keyboardFocus,
			},
		]"
		@keyup.tab="keyboardFocus = true"
		@focusout="keyboardFocus = false"
	>
		<div
			class="input-label"
			v-if="label || $slots['help-text']"
		>
			<TnLabel
				:dark="dark"
				v-if="label"
				:for="uniqueId"
				:size="size"
				>{{ label }}</TnLabel
			>
			<div class="help-text">
				<!-- @slot Slot for displaying help text on the upper right on top of the input field -->
				<slot name="help-text">
					<span v-if="helpText">{{ helpText }}</span>
				</slot>
			</div>
		</div>
		<div class="textarea-wrapper">
			<textarea
				@update:modelValue="updateValue"
				@mousedown="handleResize"
				@mouseup="handleResize"
				v-model="internalValue"
				v-bind="$attrs"
				:id="uniqueId"
				:name="name"
				:placeholder="placeholder"
				:class="[
					{
						clearable,
					},
				]"
				:maxlength="maxLength || -1"
				:disabled="disabled"
				ref="textarea"
			></textarea>

			<TnIcon
				v-if="clearable && internalValue && hideButton && !disabled"
				class="textarea-wrapper-clear-icon"
				name="remove"
				@click="handleClear"
				@keyup.enter="handleClear"
				tabindex="0"
			/>

			<TnIcon
				v-if="disabled"
				class="textarea-wrapper-disabled-icon"
				name="lock-outline-closed"
			/>
		</div>

		<div
			v-if="maxLength"
			:class="['input-wrapper-characterlimit', { limit: characters >= maxLength }]"
		>
			{{ characters }}/{{ maxLength }}
		</div>

		<div class="support-text">
			<TnSupportText
				v-if="$slots['hint']"
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying hint-text (no icon) via TnSupportText -->
				<slot name="hint"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['error']"
				critical
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying critical/error messages via TnSupportText -->
				<slot name="error"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['warning']"
				warning
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying warning messages via TnSupportText -->
				<slot name="warning"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['info']"
				info
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying informational messages via TnSupportText -->
				<slot name="info"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['success']"
				success
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying success messages via TnSupportText -->
				<slot name="success"></slot>
			</TnSupportText>
		</div>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * Text field area is used for collecting user provided information.
 * @displayName TnTextArea
 */
export default defineComponent({
	name: "TnTextArea",
	inheritAttrs: false,

	props: {
		/**
		 * Name of the input field for use with forms
		 */
		name: {
			type: String,
		},
		/**
		 * The label for textarea
		 */
		label: {
			type: String,
		},
		/**
		 * Text in input-field, for use with V-model or just as a prop for start-value
		 */
		modelValue: {
			type: String,
			default: "",
		},
		/**
		 * Size of the input area
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "s",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Disables component
		 */
		disabled: {
			type: Boolean,
		},
		/**
		 * The placeholder for textarea
		 */
		placeholder: {
			type: String,
		},
		/**
		 * Enables clear-icon top right of textarea for clearing input-value
		 */
		clearable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Help text that shows over the input on the right. Could be used to communicate that input is optional.
		 */
		helpText: {
			type: String,
			default: "",
		},
		/**
		 * Set maxlength for textarea
		 */
		maxLength: {
			type: Number,
		},
		/**
		 * Set if textarea is to be required
		 */
		required: {
			type: Boolean,
		},
		/**
		 * Dark
		 */
		dark: {
			type: Boolean,
		},
	},

	data() {
		return {
			internalValue: this.modelValue,
			keyboardFocus: false,
			inputWidth: 0,
			hideButton: false,
		};
	},

	computed: {
		isClearable() {
			return this.clearable && !this.disabled && this.internalValue?.length > 0;
		},
		characters() {
			return this.internalValue ? this.internalValue.length : 0;
		},
		uniqueId() {
			return useId();
		},
	},

	watch: {
		modelValue: {
			immediate: true,
			handler(newValue) {
				this.internalValue = newValue;
			},
		},
	},

	mounted() {
		this.getElementWidth();
	},

	methods: {
		updateValue(event) {
			this.$emit("update:modelValue", event.target.value);
		},
		handleClear(event) {
			if (this.isClearable) {
				event.stopPropagation();
				this.internalValue = "";
				this.$emit("cleared");
			}
		},
		handleResize(event) {
			if (event.type === "mousedown") {
				this.hideButton = false;
			} else {
				this.hideButton = true;
				this.getElementWidth();
			}
		},
		getElementWidth() {
			this.inputWidth = this.$refs.textarea.clientWidth;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "sass:math";
@use "sass:color";
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.input-wrapper {
	--textarea-background-color: #{variables.$color-neutrals-white};
	--textarea-background-color-disabled: #{variables.$color-neutrals-50-tint};
	--textarea-border-color: #{variables.$color-neutrals-400-tint};
	--textarea-border-hover-color: #{variables.$color-cta-hover};
	--textarea-border-disabled-color: #{variables.$color-neutrals-100-tint};
	--textarea-focus-color: #{variables.$color-cta-active};
	--textarea-error-color: #{variables.$color-critical-500-core};
	--textarea-text-color: #{variables.$color-primary-dark};
	--textarea-text-placeholder-color: #{variables.$color-neutrals-600-shade};
	--textarea-text-disabled-color: #{variables.$color-neutrals-400-tint};
	--textarea-icon-color: #{variables.$color-neutrals-500-core};

	box-sizing: border-box;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&.dark {
		--textarea-background-color: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.24);
		--textarea-background-color-disabled: rgba(#{hexToRGB(variables.$color-cta-dark-disabled)}, 0.18);
		--textarea-border-color: #{variables.$color-neutrals-800-shade};
		--textarea-border-hover-color: #{variables.$color-cta-dark-hover};
		--textarea-focus-color: #{variables.$color-cta-dark-active};
		--textarea-border-disabled-color: #{variables.$color-neutrals-50-tint};
		--textarea-error-color: #{variables.$color-critical-500-core};
		--textarea-text-color: #{variables.$color-cta-dark-default};
		--textarea-text-placeholder-color: #{variables.$color-neutrals-400-tint};
		--textarea-text-disabled-color: #{variables.$color-neutrals-200-tint};
		--textarea-icon-color: #{variables.$color-neutrals-50-tint};
	}

	.input-label {
		display: flex;
		justify-content: space-between;
	}

	&.s {
		gap: 6px;

		textarea {
			padding: 11px 16px;

			@extend %font-text-s;
		}
	}

	&.m {
		textarea {
			padding: 12px 16px;

			@extend %font-text-m;
		}
	}

	&.l {
		textarea {
			padding: 16px;

			@extend %font-text-l;
		}
	}

	&-icon {
		position: absolute;
		left: variables.$spacing-m;
		top: variables.$spacing-xl;
	}

	&-characterlimit {
		color: var(--textarea-text-color);

		@extend %font-text-xs;

		&.limit {
			color: var(--textarea-error-color);
		}
	}

	.textarea-wrapper {
		&-clear-icon {
			position: absolute;
			right: 0;
			cursor: pointer;
			margin-top: variables.$spacing-xs;
			margin-right: variables.$spacing-xs;
			color: var(--textarea-icon-color);

			&:focus {
				outline: none;
			}

			&:focus-visible {
				outline: 1px solid var(--textarea-focus-color);
				border-radius: 4px;
			}
		}

		&-disabled-icon {
			position: absolute;
			right: 0;
			margin-top: variables.$spacing-xs;
			margin-right: variables.$spacing-xs;
			color: var(--textarea-icon-color);
		}
	}

	textarea {
		box-sizing: border-box;
		border-radius: 8px;
		border: 1px solid var(--textarea-border-color);
		outline-offset: 1px;
		width: 100%;
		height: 100%;
		resize: vertical;
		overflow: auto;
		color: var(--textarea-text-color);
		background-color: var(--textarea-background-color);

		@extend %font-text-m;

		&::placeholder {
			color: var(--textarea-text-placeholder-color);
		}

		&:disabled {
			background-color: var(--textarea-background-color-disabled);
			border: 1px solid var(--textarea-border-disabled-color) !important;
			resize: none;

			* {
				user-select: none;
			}
		}

		&:focus-visible,
		&:active:not(&:disabled) {
			border-color: transparent;
			box-shadow: 0 0 0 2px var(--textarea-focus-color);
		}

		&:focus {
			outline: none;
		}

		&:hover {
			border: 1px solid var(--textarea-border-hover-color);
		}

		&.clearable {
			padding-right: 50px;
		}
	}

	&.error textarea {
		--textarea-border-color: transparent;

		box-shadow: 0 0 0 2px var(--textarea-error-color);
	}
}

.support-text {
	display: flex;
	justify-content: flex-start;
}
</style>
